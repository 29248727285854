// components
import Seo from 'src/components/Seo';
// hooks
import useBlogMetaData from 'src/cms/data/blog/useBlogMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const blogMetaData = useBlogMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={blogMetaData?.title}
      description={blogMetaData?.description}
      schema={blogMetaData?.schema}
    />
  );
};

export { default } from './Blog';
